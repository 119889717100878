import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { AlignContentProps } from './AlignContent';
import { breakpoints } from '~/theme';

export const StyledAlignContent = styled.div<AlignContentProps>(
    ({ theme, textColor }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        color: textColor,
        gap: theme.spaces[3],
        fontSize: theme.fontSizesFluid.lg,
        maxWidth: theme.maxWidthInCharacters.lg,

        [breakpoints.md]: {
            gap: theme.spaces[5],
        },
    }),
    switchProp('alignment', {
        left: {
            alignItems: 'flex-start',
        },
        right: {
            marginLeft: 'auto',
            alignItems: 'flex-end',
            textAlign: 'right',
        },
        center: {
            margin: '0 auto',
            alignItems: 'center',
            textAlign: 'center',
        },
    }),
    switchProp('position', {
        top: {
            justifyContent: 'flex-start',
        },
        center: {
            justifyContent: 'center',
        },
        bottom: {
            justifyContent: 'flex-end',
        },
    })
);
