import React, { useRef } from 'react';
import { useTheme } from '@emotion/react';
import { m, useInView } from 'framer-motion';
import { Link as LinkType } from '~/lib/data-contract';
import { ButtonProps, RawHtml, RouterLink, Text, TextVariants } from '~/shared/components';
import { UseThemeShadeProps, Spacer, ThemeType, useThemeShade } from '~/theme';
import { StyledBlockSubheadline, StyledBlockLink, StyledBlockHeadline } from './styled';
import { useIsInitialRenderState } from '~/shared/hooks';
import { useModule } from '~/templates';
import { useTranslation } from '$shared/utils';

export type SpotTextBlockProps = {
    headline?: string;
    subheadline?: string;
    subHeadlineUppercase?: boolean;
    text?: string;
    html?: string;
    callToAction?: LinkType;
    buttonVariant?: Exclude<ButtonProps['variant'], undefined>;
    headlineVariant?: TextVariants;
    appearTravel?: number;
    appearDelay?: number;
    appearStagger?: number;
    onAppearAnimation?: 'none' | 'stagger';
    useTranslationForLinkTitle?: boolean;
} & UseThemeShadeProps;

const defaultVariant = (theme: ThemeType, travel: number) => ({
    hidden: {
        opacity: 0,
        y: travel,
    },
    shown: {
        opacity: 1,
        y: 0,
        transition: {
            ease: theme.animations.bezierValues['entrance']['expressive'],
            opacity: {
                duration: theme.animations.durations['slow02'],
            },
            y: {
                duration: theme.animations.durations['slow01'],
            },
        },
    },
});

export const SpotTextBlock = ({
    backgroundColor,
    headline,
    subheadline,
    subHeadlineUppercase,
    text,
    html,
    callToAction,
    buttonVariant,
    headlineVariant = 'display2',
    appearTravel = 24,
    appearDelay = 0.5,
    appearStagger = 0.2,
    onAppearAnimation = 'stagger',
    useTranslationForLinkTitle,
}: SpotTextBlockProps) => {
    const theme = useTheme();
    const { translate } = useTranslation();
    const moduleTheme = useThemeShade({ backgroundColor });
    const ref = useRef<HTMLDivElement | null>(null);
    const isInView = useInView(ref, { margin: '100px 0px', once: true });
    const { isInitialRender } = useIsInitialRenderState();
    const { index } = useModule();
    const shouldShowInitially = index === 0 && isInitialRender;
    const shouldAnimate = onAppearAnimation !== 'none' && !shouldShowInitially;

    const childVariant = shouldAnimate ? defaultVariant(theme, appearTravel) : undefined;
    const linkVariant = shouldAnimate ? defaultVariant(theme, 0) : undefined;
    const parentVariant = shouldAnimate
        ? {
              shown: {
                  transition: {
                      delayChildren: appearDelay,
                      staggerChildren: onAppearAnimation === 'stagger' ? appearStagger : 0,
                      ease: theme.animations.bezierValues['entrance']['expressive'],
                  },
              },
          }
        : undefined;

    return (
        <m.div
            ref={ref}
            variants={parentVariant}
            initial="hidden"
            animate={isInView || shouldShowInitially ? 'shown' : 'hidden'}
        >
            {subheadline && (
                <StyledBlockSubheadline
                    variants={childVariant}
                    children={subheadline}
                    variant={subHeadlineUppercase ?? true ? 'overline' : 'caption'}
                    shade={moduleTheme?.headlineShade}
                />
            )}
            {headline && (
                <>
                    <Spacer space="2" />
                    {/* Can't extend headline with motion component, so must wrap instead */}
                    <m.div variants={childVariant}>
                        <StyledBlockHeadline
                            children={headline}
                            variant={headlineVariant}
                            shade={moduleTheme?.headlineShade}
                        />
                    </m.div>
                </>
            )}
            {(html || text) && (
                <>
                    <Spacer space="3" />
                    {/* Can't extend rawHtml with motion component, so must wrap instead */}
                    <m.div variants={childVariant}>
                        {html && (
                            <RawHtml html={html} backgroundColor={moduleTheme?.backgroundColor} />
                        )}
                        {text && <Text shade={moduleTheme?.textShade} children={text} />}
                    </m.div>
                </>
            )}
            {callToAction?.url && (
                <>
                    <Spacer space="4" />
                    <RouterLink href={callToAction.url}>
                        <StyledBlockLink
                            as="a"
                            variants={linkVariant}
                            href={callToAction.url}
                            variant={buttonVariant}
                            target={callToAction.target}
                            title={callToAction.title}
                            children={
                                useTranslationForLinkTitle &&
                                (callToAction.text?.includes('/') || !callToAction.text)
                                    ? translate('modules.heroModuleLinkTitle')
                                    : callToAction.text || callToAction.title
                            }
                            shade={moduleTheme?.buttonShade}
                        />
                    </RouterLink>
                </>
            )}
        </m.div>
    );
};
