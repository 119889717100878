import styled from '@emotion/styled';
import { Text, Button } from '~/shared/components';
import { m } from 'framer-motion';
import { Headline } from '../Headline/Headline';

export const StyledBlockSubheadline = styled(m(Text))({
    whiteSpace: 'pre-line',
});

export const StyledBlockHeadline = styled(Headline)({
    whiteSpace: 'pre-line',
});

export const StyledBlockLink = styled(m(Button))({
    pointerEvents: 'auto', // Make sure button is clickable when nested inside pointer-events: 'none'
});
